import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import req from '../../../Utils/req';
import {
  setAttach,
  setChatScroll,
  setChats,
  setLoadMessage,
  setMessage, setPaginates, setSelected,
} from '../ChatReducer';
import { useGlobalCtx } from '../../../Context/GlobalProvider';
import AlertToster from '../../../Components/AlertToster';
import { useAuth } from '../../../Context/AuthProvider';
import LinkDetect from '../../../Utils/LinkDetect';

export default function useMessages() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { socket, callWindows } = useGlobalCtx();
  const { pagination } = useSelector((state) => state.chatsStore);
  const [msgLoading, setMsgLoading] = useState(false);

  const getOneChat = ({ chatid, page }) => {
    setMsgLoading(true);
    return req({ uri: `chat/${chatid}?page=${page || 1}` })
      .then(({ data }) => data)
      .catch((e) => console.log(e))
      .finally(() => setMsgLoading(false));
  };

  const getMSGs = ({ page }) => {
    setMsgLoading(true);
    if (id !== 'create') {
      req({ uri: `message/${id}?page=${page || 1}` }).then(({ data }) => {
        dispatch(setPaginates({ name: 'msgHasNext', value: data.nextPage }));
        if (page !== 1) dispatch(setLoadMessage(data.docs.reverse()));
        else {
          dispatch(setSelected({ msg: data?.docs?.reverse() || [], chatId: id }));
          dispatch(setChatScroll());
        }
      }).catch((e) => console.log(e)).finally(() => setMsgLoading(false));
    }
  };
  const onScrollLoadMSG = (e) => {
    const even = e.target;
    if (even.scrollTop === 0 && pagination.msgHasNext) {
      getMSGs({ page: pagination.msgHasNext });
    }
  };
  const call = ({ mode }) => {
    callWindows[id] = window.open(`/call/${mode}/${id}`, '_blank', 'width=auto, height=auto');
    callWindows[id].addEventListener('beforeunload', () => delete callWindows[id]);
    socket.emit('call', { chatId: id, mode });
  };

  const join = (chatId, data) => {
    if (callWindows[chatId]) return callWindows[chatId].focus();
    callWindows[chatId] = window.open(`/call/${data.mode}/${chatId}`, '_blank', 'width=auto, height=auto');
    return callWindows[chatId].addEventListener('beforeunload', () => delete callWindows[id]);
  };
  const deleteHandler = ({
    chatId, mId, state, update, self = false,
  }) => {
    const oldMsg = [...state().chatsStore.selected.msg];
    const oldChats = state().chatsStore.chats;
    let chat = oldChats.find((c) => c.id === chatId);
    const index = oldMsg.findIndex((m) => m.id === mId);
    if (self && oldMsg[index].author.id !== user.id) throw new Error(AlertToster('Unauthentic', 'error'));

    if (chat && chat.lastMessage.id === mId) {
      chat = {
        ...chat,
        lastMessage:
          { ...chat.lastMessage, message: '[Deleted]' },
      };
      update(setChats(oldChats.map((c) => (c.id === chatId ? chat : c))));
    }

    const links = LinkDetect(oldMsg[index]?.message);
    if (oldMsg[index].attachments.length !== 0 || links.length !== 0) {
      const atts = { ...state().chatsStore.attach };
      const newAtt = atts.attachment.filter(
        (at) => !oldMsg[index].attachments.some((a) => a.id === at.id),
      );
      const newLinks = atts.links.filter((at) => !links.some((a) => a === at));
      update(setAttach({ attachment: newAtt, links: newLinks }));
    }
    oldMsg[index] = { ...oldMsg[index], deleted: true };
    update(setSelected({ msg: oldMsg, chatId: id }));
  };
  const onMsgDelete = ({ mId, chatId }) => {
    try {
      dispatch((update, state) => {
        deleteHandler({
          update, state, mId, chatId, self: true,
        });
      });
      socket.emit('messageUpdate', { id: mId, chatId: id, reaction: false });
    } catch (error) {
      console.log(error);
    }
  };
  const onSocketDeleteMsg = (data) => {
    dispatch((update, state) => {
      deleteHandler({
        update, state, mId: data.id, chatId: data.chat.id,
      });
    });
  };
  const getAttachs = () => {
    try {
      req({ uri: `message/attach/${id}` })
        .then(({ data }) => {
          dispatch(setAttach({
            attachment: data.attachments.reverse(), links: data.links.reverse(),
          }));
        }).catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };
  const getMessage = (data) => {
    dispatch((update, state) => {
      const selectId = state().chatsStore.selected.chatId;
      if (selectId === data.chat.id) {
        if (data.attachments.length > 0 || data.links.length > 0) {
          const { attachment, links } = state().chatsStore.attach;
          update(setAttach(
            {
              attachment: [...data.attachments, ...attachment], links: [...data.links, ...links],
            },
          ));
        }
        update(setMessage(data));
      }
      const oldChats = state().chatsStore.chats;
      const updateChats = oldChats.map(
        (c) => (c.id === data.chat.id ? ({ ...c, lastMessage: data }) : c),
      );
      update(setChats(updateChats));
    });
  };
  const onSeenMsg = ({ cId }) => {
    req({ method: 'PATCH', uri: `message/${cId}` })
      .then(() => {
        console.log('seen!');
      }).catch((e) => console.log(e));
  };
  useEffect(() => {
    socket.on('message', (data) => getMessage(data));
    socket.on('messageUpdate', (data) => onSocketDeleteMsg(data));
    if (id !== 'create') socket.emit('entry', { method: 'join', chat: id });
    return () => {
      socket.off('messageUpdate');
      socket.off('message');
      socket.off('entry');
    };
  }, []);
  return {
    // state
    msgLoading,
    // methods
    getMSGs,
    call,
    join,
    onMsgDelete,
    getAttachs,
    onScrollLoadMSG,
    onSeenMsg,
    getOneChat,
  };
}
