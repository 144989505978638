import { createSlice } from '@reduxjs/toolkit';

function recursivelyFind(task, data) {
  task.child = task.child.map((e) => {
    if (!(JSON.stringify(e).includes(data.id))) return e;
    return recursivelyFind(e, data);
  });
  if (task.id === data.id) {
    const { child, ...rest } = data;
    return { ...task, ...rest };
  }
  return task;
}

function recursivelyDisable(task, payload) {
  task.child = task.child.map((e) => recursivelyDisable(e, payload));
  const active = task.child.find((ech) => ech?.tracking || ech?.block || false);
  if (active) task.block = true;
  if (task.id === payload.id) {
    const data = { ...task, ...payload, tracking: payload.pause };
    return data;
  }
  return task;
}

function recursivelyEnable(task, payload) {
  task.child = task.child.map((e) => recursivelyEnable(e, payload));
  const active = task.child.find((ech) => ech?.tracking || ech?.block || false);
  if (!active) task.block = false;
  if (task.id === payload.id) {
    const data = { ...task, ...payload, tracking: false };
    return data;
  }
  return task;
}

function recursiveCalculate(task) {
  if (task.child.length < 1) return { ...task, sum: 0 + task.elapsedtime };
  task.child = task.child.map((e) => recursiveCalculate(e));
  const tot = task.child.reduce((acc, curr) => acc + curr.sum, task.elapsedtime);
  task.sum = tot;
  return task;
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    pagination: {},
    projects: [],
    pUsers: [],
    favourites: [],
    view: [],
    taskSelected: [],
    customField: {},
    currentDrawer: {},
    createTaskOpen: {
      placeholder: 'New Task', id: '0', field: '',
    },
    loading: false,
    siblings: [],
  },
  reducers: {
    setProjects: (state, action) => {
      if (typeof action.payload === 'function') state.projects = action.payload(state);
      else state.projects = action.payload;
    },
    setUpdateProject: (state, action) => {
      const findIndex = state.projects.findIndex((p) => p.id === action.payload.id);
      state.projects[findIndex] = action.payload;
    },
    removeProject: (state, action) => {
      state.projects = state.projects.filter((p) => p.id !== action.payload);
    },
    setAddProject: (state, action) => {
      state.projects = [...state.projects, action.payload];
    },
    setFavourites: (state, action) => {
      if (typeof action.payload === 'function') state.favourites = action.payload(state);
      else state.favourites = action.payload;
    },
    mergeProjects: (state, action) => {
      state.projects = [...state.projects, action.payload];
    },
    taskUpdate: (state, { payload }) => {
      const { data, pID } = payload;
      const tabs = ['tasks', 'bugs', 'sprints'];

      /* updates in view */
      state.view = state.view.map((tab) => ({
        ...tab,
        tasks: tab.tasks.map((
          task,
        ) => {
          if (!(JSON.stringify(task).includes(data.id))) return task;
          return recursivelyFind(task, data);
        }),
      }));

      /* updates in projects */
      state.projects.forEach((project) => {
        if (project.id === pID) {
          // eslint-disable-next-line consistent-return
          tabs.forEach((tab) => {
            if (tab === 'sprints') {
              return project[tab].forEach((ech) => {
                ech.tasks = ech.tasks.map((task) => {
                  if (!(JSON.stringify(task).includes(data.id))) return task;
                  return recursivelyFind(task, data);
                });
              });
            }
            project[tab] = project[tab].map((task) => {
              if (!(JSON.stringify(task).includes(data.id))) return task;
              return recursivelyFind(task, data);
            });
          });
        }
      });
    },
    addOrRemoveMembers: (state, action) => {
      const {
        projectId, member, method,
      } = action.payload;
      state.projects = state.projects.map((project) => {
        if (project.id !== projectId) return project;
        project.members = method === 'add' ? [member, ...project.members] : project.members.filter((m) => m.id !== member.id);
        return project;
      });
    },
    setView: (state, action) => {
      if (typeof action.payload === 'function') state.view = action.payload(state);
      else state.view = action.payload;
    },
    setUpdateView: (state, { payload }) => {
      const index = state.view.findIndex((v) => v.id === payload.id);
      state.view[index] = payload;
    },
    setSprint: (state, action) => {
      const oldView = [...state.view];
      state.view = [...oldView, action.payload];
    },
    removeSprint: (state, action) => {
      const oldView = [...state.view];
      state.view = oldView.filter((v) => v.id !== action.payload.id);
    },
    // Remove task from view
    removeView: (state, action) => {
      const { viewId, task } = action.payload;
      state.view = state.view.map((p) => {
        if (p.id === viewId) {
          const d = p.tasks.filter((t) => t.id !== task.id);
          return { ...p, tasks: d };
        }
        return p;
      });
    },
    addNewSprint: (state, action) => {
      const { group, id, data } = action.payload;
      state[group] = state[group].map((p) => {
        if (p.id === id) {
          p.sprints = [...p.sprints, data];
          return p;
        }
        return p;
      });
    },
    updateDrawer: (state, action) => {
      state.currentDrawer = action.payload;
    },
    setTaskSelection: (state, action) => {
      state.taskSelected = action.payload;
    },
    setCustomField: (state, action) => {
      state.customField = ({ ...state.customField, ...action.payload });
    },
    resetCustomField: (state) => {
      state.customField = ({});
    },
    onCreateTaskOpen: (state, action) => {
      state.createTaskOpen = action.payload;
    },
    setPUser: (state, action) => {
      state.pUsers = action.payload;
    },
    rearrangeCustomfields: (state, action) => {
      state.projects.forEach((project) => {
        if (project.id === action.payload.projectId) {
          const { customFields } = project;
          const hidden = customFields.filter(({ show }) => !show);
          const copy = [...customFields.filter(({ show }) => show)];
          const target = copy.splice(action.payload.itemIndex, 1);
          const newArrange = [
            ...copy.slice(0, action.payload.dropIndex),
            ...target, ...copy.slice(action.payload.dropIndex),
            ...hidden,
          ];
          project.customFields = newArrange;
          if (action.payload.callback) {
            action.payload.callback(newArrange, action.payload.projectId);
          }
        }
      });
    },
    changeOwner: (state, { payload }) => {
      const { projectId, owner } = payload;
      state.projects = state.projects.map((proj) => {
        if (proj.id === projectId) {
          return { ...proj, owner };
        }
        return proj;
      });
    },

    disableParent: (state, { payload }) => {
      const pID = typeof payload.project === 'object' ? payload.project.id : payload.project;
      state.view = state.view.map((bundel) => ({
        ...bundel,
        tasks: bundel.tasks.map((task) => {
          if (!(JSON.stringify(task).includes(payload.id))) return task;
          return recursivelyDisable(task, payload);
        }),
      }));
      const tabs = ['tasks', 'bugs', 'sprints'];
      state.projects.forEach((project) => {
        if (project.id === pID) {
          // eslint-disable-next-line consistent-return
          tabs.forEach((tab) => {
            if (tab === 'sprints') {
              return project[tab].forEach((ech) => {
                ech.tasks = ech.tasks.map((
                  task,
                ) => {
                  if (!(JSON.stringify(task).includes(payload.id))) return task;
                  return recursivelyFind(task, { ...payload, tracking: true });
                });
              });
            }
            project[tab] = project[tab].map((
              task,
            ) => {
              if (!(JSON.stringify(task).includes(payload.id))) return task;
              return recursivelyFind(task, { ...payload, tracking: true });
            });
          });
        }
      });
    },
    enableParent: (state, { payload }) => {
      const pID = typeof payload.project === 'object' ? payload.project.id : payload.project;
      state.view = state.view.map((bundel) => ({
        ...bundel,
        tasks: bundel.tasks.map((task) => {
          if (!(JSON.stringify(task).includes(payload.id))) return task;
          return recursivelyEnable(task, payload);
        }),
      }));
      const tabs = ['tasks', 'bugs', 'sprints'];
      state.projects.forEach((project) => {
        if (project.id === pID) {
          // eslint-disable-next-line consistent-return
          tabs.forEach((tab) => {
            if (tab === 'sprints') {
              return project[tab].forEach((ech) => {
                ech.tasks = ech.tasks.map((
                  task,
                ) => {
                  if (!(JSON.stringify(task).includes(payload.id))) return task;
                  return recursivelyFind(task, { ...payload, tracking: false });
                });
              });
            }
            project[tab] = project[tab].map((
              task,
            ) => {
              if (!(JSON.stringify(task).includes(payload.id))) return task;
              return recursivelyFind(task, { ...payload, tracking: false });
            });
          });
        }
      });
    },
    calculateTime: (state) => {
      state.view = state.view.map((bundel) => ({
        ...bundel,
        tasks: bundel.tasks.map((task) => recursiveCalculate(task)),
      }));
    },
    siblings: (state, { payload }) => {
      state.siblings = payload.value;
    },
  },
});

export const {
  toggleDropdown,
  setProjects,
  setUpdateProject,
  setAddProject,
  addOrRemoveMembers,
  mergeProjects,
  setView,
  setUpdateView,
  setSprint,
  addNewSprint,
  removeProject,
  updateDrawer,
  setTaskSelection,
  removeView,
  setCustomField,
  resetCustomField,
  setDataLoad,
  onCreateTaskOpen,
  setFavourites,
  setPUser,
  removeSprint,
  taskUpdate,
  rearrangeCustomfields,
  changeOwner,
  disableParent,
  enableParent,
  calculateTime,
  siblings,
} = projectsSlice.actions;
export default projectsSlice.reducer;
