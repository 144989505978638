import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Context/AuthProvider';
import req from '../../../Utils/req';
import {
  removeChat, setActionModal, setChats, setPaginates,
} from '../ChatReducer';
import CheckId from '../../../Utils/CheckId';
import { useGlobalCtx } from '../../../Context/GlobalProvider';
import AlertToster from '../../../Components/AlertToster';
// import AlertToster from '../../../Components/AlertToster';

export default function useChats() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { socket } = useGlobalCtx();
  const { user, setUser, getAllUser } = useAuth();
  const refMultiSelect = useRef(null);
  const { nPagination } = useSelector((state) => state.notifyStore);
  const {
    selectedUsers, actionModal, pagination, chats,
  } = useSelector((state) => state.chatsStore);

  const getChats = (page = 1) => {
    req({ uri: `chat?page=${page || 1}` })
      .then(({ data }) => {
        const oldChatList = [...chats, ...data.docs]
          .map(JSON.stringify);
        const oldChatSet = new Set(oldChatList);
        const chatJson = Array.from(oldChatSet).map(JSON.parse);
        dispatch(setChats(chatJson));
        dispatch(setPaginates({ name: 'chatHasNext', value: data.nextPage }));
      })
      .catch((e) => console.log(e));
  };
  /**
   * @param {activeCt} activeCt
   * activeCt is Select chat
   * @returns target image
   */
  const getAvatar = (activeCt) => {
    if (!activeCt?.icon || activeCt?.icon === null) {
      return activeCt?.members?.length === 1
        ? activeCt?.members[0].avatar
        : activeCt?.members?.filter((m) => m.id !== user.id)[0]?.avatar;
    }
    return activeCt?.icon;
  };
  /**
   * @param {chat id} cId
   * delete chat functionality
   */

  const onLeave = ({
    cId, selectUsrs, name = '', pinChat,
  }) => {
    const fd = new FormData();
    const userIds = selectUsrs.map((u) => u.id);
    const cName = name?.split(',');
    fd.append('data', JSON.stringify({
      name: cName.length > 1 ? null : name, members: userIds, action: 'remove', actionMembers: [user.id], pinChat,
    }));
    const idx = chats.findIndex((c) => c.id === cId);
    setUser((u) => ({ ...u, pinnedChats: u.pinnedChats.filter((c) => c !== cId) }));
    navigate(`/chat/${chats[idx + 1].id}`);
    req({ method: 'PATCH', uri: `chat/${cId}`, data: fd })
      .then(({ data }) => (data?.error ? AlertToster(data.error, 'warning') : ''))
      .catch((e) => console.log(e));
  };
  const deleteChat = (cId) => {
    if (cId !== null) {
      setUser((usr) => {
        if (usr.pinnedChats.includes(cId)) {
          const pinChats = usr.pinnedChats.filter((c) => c !== cId);
          req({ method: 'PATCH', uri: `chat/pin/${cId}`, data: { action: 'Unpin' } }).catch((e) => console.log(e));
          return { ...usr, pinnedChats: pinChats };
        }
        return usr;
      });
      req({ method: 'DELETE', uri: `chat/${cId}` })
        .then(({ data }) => {
          if (data.error) AlertToster(data.error, 'warning');
          else {
            dispatch((update, state) => {
              const oldChats = state().chatsStore.chats;
              const index = CheckId({ lists: oldChats, params: cId, index: true });
              // if (oldChats.length > 2 && (index - 1 > 1))
              navigate(`/chat/${oldChats[index + 1].id}`);
              update(removeChat(cId));
            });
          }
        }).catch((e) => console.log(e));
    }
  };
  const onSocketRemove = (dChat) => {
    dispatch((update, state) => {
      const { selected, chats: rChats } = state().chatsStore;
      const idx = rChats.findIndex((c) => c.id === dChat.id);
      if (dChat.id === selected.chatId) navigate(`/chat/${rChats[idx + 1].id}`);
      update(removeChat(dChat.id));
    });
    setUser((usr) => {
      if (usr.pinnedChats.includes(dChat.id)) {
        const pinChats = usr.pinnedChats.filter((c) => c !== dChat.id);
        req({ method: 'PATCH', uri: `chat/pin/${dChat.id}`, data: { action: 'Unpin' } });
        return { ...usr, pinnedChats: pinChats };
      }
      return usr;
    });
  };
  const onPinChat = (chatId, action) => {
    try {
      setUser((u) => ({ ...u, pinnedChats: (action === 'Pin') ? [chatId, ...u.pinnedChats] : u.pinnedChats.filter((p) => p !== chatId) }));
      req({ method: 'PATCH', uri: `chat/pin/${chatId}`, data: { action } })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };
  const getNameTruncate = (activeCt, num = 2) => {
    if (activeCt?.name) {
      const n = activeCt?.name?.split(',');
      if (n?.length > 1) return (n.slice(0, num).toString()).concat((n.length - num) > 0 ? `,+${n.length - num}` : '');
      return activeCt.name;
    }
    return activeCt?.members?.length === 1
      ? activeCt?.members[0].name
      : activeCt?.members?.filter((m) => m.id !== user.id)[0]?.name;
  };
  const onScrollUsers = (e) => {
    e.preventDefault();
    const even = e.target;
    if (even.scrollTop + even.clientHeight === even.scrollHeight) {
      if (nPagination.userNextPage) {
        getAllUser(nPagination.userNextPage);
      }
    }
  };
  const onScrollChats = (e) => {
    const even = e.target;
    if (even.scrollHeight - (even.scrollTop + even.clientHeight) < 2) {
      if (pagination.chatHasNext) {
        getChats(pagination.chatHasNext);
      }
    }
  };
  const onCreateChat = (fData) => {
    const userId = selectedUsers.map((u) => u.id);
    if (userId.length === 0) AlertToster('User not selected', 'warning');
    else {
      const fd = new FormData();
      const reqObj = {
        icon: fData.icon[0],
        data: JSON.stringify({ name: fData.name, members: userId }),
      };
      Object.keys(reqObj).forEach((key) => fd.append(key, reqObj[key]));
      req({ method: 'POST', uri: 'chat', data: fd })
        .then(({ data }) => {
          dispatch(setActionModal({ show: false, chatData: null }));
          navigate(`/chat/${data.id}`);
          AlertToster('Chat created!', 'success');
        }).catch((e) => console.log({ error: e }));
    }
  };
  const checkMemberAddOrRemove = () => {
    const { members } = actionModal.chatData;
    const memberIds = members.map((m) => m.id);
    const selMbid = [...selectedUsers, { id: user.id }].map((m) => m.id);
    if (memberIds.length > selMbid.length) {
      const rmMemebers = memberIds.filter((m) => !selMbid.includes(m));
      return { action: 'remove', actionMembers: rmMemebers };
    }
    if (memberIds.length < selMbid.length) {
      const adMemebers = selMbid.filter((m) => !memberIds.includes(m));
      return { action: 'created', actionMembers: adMemebers };
    }
    return { action: 'update', actionMembers: [] };
  };
  const onUpdateChat = (fData) => {
    const { name, id } = fData;
    const { action, actionMembers } = checkMemberAddOrRemove();
    const userIds = selectedUsers.map((s) => s.id);
    const fd = new FormData();
    const reqObj = {
      icon: fData?.icon === undefined ? '' : fData.icon[0],
      data: JSON.stringify({
        name, members: [...userIds, user.id], action, actionMembers,
      }),
    };
    Object.keys(reqObj).forEach((key) => fd.append(key, reqObj[key]));
    req({ method: 'PATCH', uri: `chat/${id}`, data: fd })
      .then(({ data }) => {
        if (!data.error) {
          dispatch(setActionModal({ show: false, chatData: null }));
          navigate(`/chat/${data.id}`);
          AlertToster('Chat updated!', 'success');
        } else AlertToster(data.error, 'warning');
      })
      .catch((e) => console.log(e));
  };
  /**
   * use Effect
   */
  useEffect(() => {
    socket.off('deleteChat');
    socket.off('createChat');
    socket.off('chatUpdate');

    socket.on('deleteChat', (dChat) => {
      // console.log({ remove: dChat });
      onSocketRemove(dChat);
    });
    socket.on('createChat', (data) => {
      dispatch((update, state) => {
        const oldChats = state().chatsStore.chats;
        update(setChats([data, ...oldChats]));
      });
    });
    // unarchived project socket
    socket.on('enabledChat', (data) => {
      dispatch((update, state) => {
        const oldChats = state().chatsStore.chats;
        update(setChats([data, ...oldChats]));
      });
    });
    socket.on('chatUpdate', (chat) => {
      // console.log({ update: chat });
      dispatch((update, state) => {
        const oldChats = state().chatsStore.chats;
        const newChats = oldChats.map((c) => (c.id === chat.id ? chat : c));
        update(setChats(newChats));
      });
    });
    return () => {
      socket.off('deleteChat');
      socket.off('createChat');
      socket.off('chatUpdate');
    };
  }, []);

  return {
    // useRef
    refMultiSelect,
    // Method
    getAvatar,
    deleteChat,
    getNameTruncate,
    onLeave,
    onPinChat,
    onScrollUsers,
    onCreateChat,
    onUpdateChat,
    onScrollChats,
  };
}
