import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Private from '../Components/Private';
import Lazy from './Lazy';

export default function Routing() {
  return (
    <Routes>
      <Route path="/invite">
        <Route path=":username" element={<Lazy name="InviteRedirect" />} />
      </Route>
      <Route path="/signup" element={<Lazy name="SignUp" />} />
      <Route path="/signin" element={<Lazy name="SignIn" />} />
      <Route path="/" element={<Private><Lazy name="Dashboard" /></Private>}>
        <Route path="/" element={<Lazy name="Project" />}>
          <Route index element={<div className="flex items-center justify-center h-screen"><p className="text-secondary text-4xl opacity-50">Project Select / Create project!!!</p></div>} />
          <Route path="/:projectId" element={<Lazy name="PBody" />} />
        </Route>
        <Route path="Dashboard" element={<Lazy name="Home" />} />
        <Route path="notification" element={<Lazy name="Notifications" />} />
        <Route path="chat/:id" element={<Lazy name="ChatPage" />} />
        <Route path="archiveprojects" element={<Lazy name="Archive" />} />
      </Route>
      <Route path="call/:method/:id" element={<Private><Lazy name="CallPage" /></Private>} />
      <Route path="/forget/:params" element={<Lazy name="ForgetPage" />} />
      <Route path="*" element={<Lazy name="Authentication" />} />
    </Routes>
  );
}
