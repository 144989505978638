/* eslint-disable no-plusplus */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable brace-style */

/**
A custom React hook that creates an editor object with various formatting options.
@returns {object} - The editor object.
*/
import { useParams } from 'react-router-dom';
import { useRef, useState } from 'react';
import req from '../../../Utils/req';
import AlertToster from '../../../Components/AlertToster';

export default function useEditor() {
  const [editorType, setEditorType] = useState('write');
  const [sizeWrite, setSizeWrite] = useState(true);
  const [selected, setSelcted] = useState('');
  const [key, setKey] = useState({ keyCode: undefined, shiftKey: false });
  const markInputRef = useRef(null);
  const { id } = useParams();
  /**
    Parses various types of text formatting to their respective Markdown syntax.
    @param {string} data - The text to be formatted.
    @returns {string} - The formatted text in Markdown syntax.
  */
  const parser = {
    bold: (data) => {
      if (data.length > 0) return `**${data.toString()}**`;
      return '**your_text**';
    },
    italic: (data) => {
      if (data.length > 0) return `_${data.toString()}_`;
      return '_your text_';
    },
    strike: (data) => {
      if (data.length > 0) return `~~${data.toString()}~~`;
      return '~~your_text~~';
    },
    capital: (data) => `# ${data.toString().toUpperCase()}`,
    underline: (data) => {
      if (data.length > 0) return `-${data.toString()}-`;
      return '-your_text-';
    },
    quote: (data) => {
      if (data.length > 0) return `> ${data.toString()}`;
      return '> your_text';
    },
    url: (data) => {
      if (data.length > 0) {
        return `[${data.toString()}](${data.toString()})`;
      }
      return '[Display Text](url)';
    },
    justify: (data) => `<p className='text-justify'>${data.toString()}</p>`,
    left: (data) => `<p className='text-left'>${data.toString()}</p>`,
    right: (data) => `<p className='text-right'>${data.toString()}</p>`,
    center: (data) => `<p className='text-center'>${data.toString()}</p>`,
    h1: (data) => {
      if (data.length > 0) return `# ${data.toString()}`;
      return '# your_text';
    },
    h2: (data) => {
      if (data.length > 0) return `## ${data.toString()}`;
      return '## your_text';
    },
    h3: (data) => {
      if (data.length > 0) return `#### ${data.toString()}`;
      return '#### your_text';
    },
    h4: (data) => {
      if (data.length > 0) return `##### ${data.toString()}`;
      return '##### your_text';
    },
    h5: (data) => {
      if (data.length > 0) return `###### ${data.toString()}`;
      return '##### your_text';
    },
    h6: (data) => {
      if (data.length > 0) return `###### ${data.toString()}`;
      return '###### your_text';
    },
    code: (data) => {
      if (data.length > 0) {
        return `\`\`\`
      ${data.toString()}
      \`\`\``;
      }
      return `\`\`\`
your_code
\`\`\``;
    },
    ullist: (data) => {
      if (data.length > 0) {
        if (data.split('\n').length > 0) return data.split('\n').map((d) => `* ${d}`).join('\n');
        return `* ${data.toString()}`;
      }
      return '* your_text';
    },
    ollist: (data) => {
      if (data.length > 0) {
        if (data.split('\n').length > 0) return data.split('\n').map((d, index) => `${index + 1}. ${d}`).join('\n');
        return `1. ${data.toString()}`;
      }
      return '1. your_text';
    },
  };

  /**
   *
   * @param {*} event
   * @ returns {String} returns the input value in the textarea
  */
  // function handleInputChange(event) {
  //   if (key.keyCode === 13 && key.shiftKey === false) setInputValue('');
  //   else setInputValue(event.target.value);
  // }
  const onWriteFileSize = () => setSizeWrite(!sizeWrite);
  /**
  Applies the specified style with the given value to the text input.
  @param {string} style - The style to apply (e.g., 'bold', 'italic', 'underline').
  @param {string} selecTedText - The selected text to apply the style to.
  */
  function applyStyle(style, selecTedText, e) {
    e.preventDefault();
    const textArea = markInputRef.current;
    const { value, selectionStart, selectionEnd } = markInputRef.current;
    const marked = (parser[style] || (() => { }))(selecTedText);
    const startPos = markInputRef.current.selectionStart;
    const endPos = markInputRef.current.selectionEnd;
    const cursorPosition = selectionEnd + marked.split(selected).join('').length;
    if (marked?.length > 0 && selecTedText?.length > 0) {
      const updatedValue = value.slice(0, selectionStart)
        + marked + value.slice(selectionEnd);
      markInputRef.current.value = updatedValue;
      // markInputRef.current.value = (updatedValue);
    }
    if (!selecTedText.length > 0) {
      markInputRef.current.value = markInputRef.current.value?.substring(0, startPos)
        + marked + markInputRef.current.value?.substring(endPos);
    }
    markInputRef.current.focus();
    textArea.setSelectionRange(cursorPosition, cursorPosition);
    setSelcted(undefined);
  }

  /**
  Inserts a tab space in the input field at the current cursor
  position or indents the selected text by one tab space.
  @returns {void}
  */
  function insertTabSpace() {
    const textarea = markInputRef.current;
    textarea.focus();
    const { selectionStart, selectionEnd } = textarea;
    const lines = markInputRef.current.value.split('\n');
    const startLineIndex = markInputRef.current.value.substring(0, selectionStart).split('\n').length - 1;
    const endLineIndex = markInputRef.current.value.substring(0, selectionEnd).split('\n').length - 1;
    const newLines = lines.map((line, index) => {
      if (index >= startLineIndex && index <= endLineIndex) {
        return `\t${line}`;
      }
      return line;
    });
    const newLine = newLines.join('\n');
    textarea.value = newLine;
    markInputRef.current.value = (newLine);
    textarea.setSelectionRange(selectionStart + 1, selectionStart + 1);
  }

  /**
  Removes 4 spaces of tab from the start of the currently selected line
  */
  function removeTabSpace() {
    let isTabAvailable;
    const textarea = markInputRef.current;
    const { selectionStart, selectionEnd } = textarea;
    const lines = markInputRef.current.value.split('\n');
    const startLineIndex = markInputRef.current.value.substring(0, selectionStart).split('\n').length - 1;
    const endLineIndex = markInputRef.current.value.substring(0, selectionEnd).split('\n').length - 1;
    const newLines = lines.map((line, index) => {
      if (index >= startLineIndex && index <= endLineIndex && line?.startsWith('\t')) {
        isTabAvailable = true;
        return line.replace(/^\t/, '');
      }
      return line;
    });
    const newLine = newLines.join('\n');
    markInputRef.current.value = newLine;
    markInputRef.current.value = (newLine);
    textarea.focus();
    if (isTabAvailable) return textarea.setSelectionRange(selectionStart - 1, selectionStart - 1);
  }

  /**
   *handle while pressing tab key,it wil prevent escaping from the input field
   */
  function handleTabkey(e) {
    if (e.keyCode === 9) {
      e.preventDefault();
      const textArea = markInputRef.current;
      const { selectionStart, selectionEnd } = textArea;
      // eslint-disable-next-line prefer-template
      const newValue = markInputRef.current.value.substring(0, selectionStart) + '    ' + markInputRef.current.value.substring(selectionEnd);
      markInputRef.current.value = newValue;
      markInputRef.current.value = (newValue);
      markInputRef.current.focus();
      markInputRef.current.setSelectionRange(selectionStart + 4, selectionStart + 4);
    }
  }
  /**
   *format the list styles after hitting enter key
   */
  function handleListStyle(event) {
    const regEx = /\d+\./;
    let lastKeyHit = '';
    event.preventDefault();
    if (event.keyCode === 13) {
      const currentValue = markInputRef.current.value;
      const cursorPosi = markInputRef.current.selectionStart;
      const lines = currentValue.substring(0, cursorPosi).split('\n');
      const currentLine = lines[lines.length - 1];
      const endLine = lines[lines.length - 2];
      if (endLine?.startsWith('* ')) {
        const updated = `* ${currentLine}`;
        lines[lines.length - 1] = updated;
        const finalValue = lines.join('\n');
        markInputRef.current.focus();
        markInputRef.current.setSelectionRange(cursorPosi + 2, cursorPosi + 2);
        markInputRef.current.value = finalValue;
        markInputRef.current.value = (finalValue);
      }
      if (regEx.test(endLine) && endLine?.includes('.')) {
        const updated = `${Number(endLine?.split('.')[0]) + 1}. ${currentLine}`;
        lines[lines.length - 1] = updated;
        const finalValue = lines.join('\n');
        markInputRef.current.focus();
        markInputRef.current.setSelectionRange(cursorPosi + 3, cursorPosi + 3);
        markInputRef.current.value = finalValue;
        markInputRef.current.value = (finalValue);
      }
      if (event.key === 'Enter' && lastKeyHit === 'Enter') {
        markInputRef.current.value = currentValue;
        markInputRef.current.value = (currentValue);
      }
    }
    lastKeyHit = event.key;
  }
  const submitMessage = (data) => {
    const { files, message, chatid } = data;
    if (files.length === 0 && message === '') AlertToster('Empty Message', 'warning');
    else {
      const fd = new FormData();
      data.files.forEach((f) => fd.append('icon[]', f[0]));
      fd.append('message', data.message);
      fd.append('chat', id || chatid);
      req({ method: 'POST', uri: 'message', data: fd }).catch((e) => console.log({ error: e }));
    }
  };
  return {
    sizeWrite,
    onWriteFileSize,
    parser,
    markInputRef,
    editorType,
    setEditorType,
    selected,
    setSelcted,
    applyStyle,
    insertTabSpace,
    removeTabSpace,
    handleTabkey,
    // handleInputChange,
    handleListStyle,
    key,
    setKey,
    submitMessage,
  };
}
