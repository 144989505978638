export default function LinkDetect(message) {
  const regex = /<a\s+href="([^"]+)"/g;

  // Array to store the extracted URLs
  const urls = [];

  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(message)) !== null) {
    urls.push(match[1]);
  }
  return urls;
}
