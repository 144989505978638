import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useGlobalCtx } from '../Context/GlobalProvider';
import req from '../Utils/req';
import {
  addArrNotify, setNotify, setPagination,
} from '../Reducers/notifyReducer';
import { setPUser } from '../Features/Projects/projectReducers';
import Register from '../serviceWorkerRegistration';

const useUser = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { socket } = useGlobalCtx();

  const register = (data) => {
    setLoading(true);
    return req({
      uri: 'user',
      method: 'POST',
      data,
    });
  };
  const getNotification = ({ userId, page = 1 }) => {
    req({ uri: `task/notify/${userId}?page=${page}` })
      .then(({ data }) => {
        dispatch((update) => {
          update(addArrNotify(data.docs));
          update(setPagination({ name: 'logNextPage', value: data.nextPage }));
        });
      })
      .catch((err) => console.log(err.message));
  };
  // Get All user of projects
  const getUsers = () => {
    req({ uri: 'user?paginate=false' })
      .then(({ data }) => {
        dispatch(setPUser(data));
      }).catch((e) => console.log(e));
  };
  const login = ({ email, password, rememberMe }) => {
    setLoading(true);
    try {
      req({ uri: 'user/login', method: 'POST', data: { email, password, rememberMe } })
        .then(({ data }) => {
          Register().then((reg) => {
            console.log(reg);
            req({ method: 'PATCH', uri: 'user/me', data: { data: JSON.stringify({ push: reg }) } }).catch((e) => console.log(e));
          }).catch((e) => console.log(e));
          setUser(data);
          getNotification({ userId: data?.id });
          socket.connect();
          getUsers();
          // notifySubscribe().then((sub) => req
          // ({ method: 'PATCH', uri: 'user/notifysub', data: { sub } }))
          //   .catch((err) => console.log(err));
          navigate('/');
        })
        .catch((e) => {
          if (e?.response) toast.error(e.response.data, 'error');
          else toast.error('Check your network', 'error');
        }).finally(() => setLoading(false));
    } catch (e) {
      console.log({ e });
    }
  };

  const logout = async () => {
    setLoading(true);
    // let res = false;
    const promise = async () => req({ uri: 'user/logout', method: 'POST' });
    await promise()
      .then(() => {
        dispatch((update) => {
          update(addArrNotify([]));
          update(setPUser(null));
          update(setNotify([]));
          update(setPagination({ name: 'logNextPage', value: null }));
        });
        setUser(null);
        navigate('/login');
        socket.disconnect();
      })
      .catch(() => toast.error('Failed to log out.', 'error'))
      .finally(() => setLoading(false));
  };

  const deleteUser = async (id) => {
    try {
      const response = await req({
        uri: `user/${id}`,
        method: 'DELETE',
        data: { owner: user.id },
      });

      if (response.status === 200) {
        // dispatch(setPUser((users) => users.filter((u) => u.id !== id)));
        dispatch((update, getState) => {
          const { pUsers } = getState().projectStore;
          const updateUsers = pUsers.filter((u) => u.id !== id);
          update(setPUser(updateUsers));
        });
        toast.success('User deleted successfully.');
      } else {
        toast.error('Failed to delete user.');
      }
    } catch (error) {
      toast.error('Error deleting user.');
    }
  };

  const updateOwn = (data) => req({ method: 'PATCH', uri: 'user/me', data });

  const profile = (id) => req({ uri: `user/profile/${id}` });

  useEffect(() => {
    setLoading(true);
    req({ uri: 'user/me' })
      .then(({ data }) => {
        setUser(data);
        getNotification({ userId: data?.id });
        // getAllUser();
        getUsers();
        socket.connect();
        Register().then((reg) => {
          req({ method: 'PATCH', uri: 'user/me', data: { data: JSON.stringify({ push: reg }) } }).catch((e) => console.log(e));
        }).catch((e) => console.log(e));
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  return {
    user,
    setUser,
    loading,
    setLoading,
    login,
    logout,
    register,
    updateOwn,
    profile,
    // getAllUser,
    getNotification,
    deleteUser,
  };
};

export default useUser;
