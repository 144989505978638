import React, {
  createContext,
  useContext,
  useMemo,
} from 'react';
// import useChat from '../Hooks/useChat';
import useEditor from '../Hooks/useMarkDown';
import useChats from '../Hooks/useChats';
import useMessages from '../Hooks/useMessages';

const chatContext = createContext();

export function ChatProvider({ children }) {
  const value = {
    ...useChats(), ...useMessages(), ...useEditor(),
  };
  const action = useMemo(() => value);
  return (
    <chatContext.Provider
      value={action}
    >
      {children}
    </chatContext.Provider>
  );
}

export const useChatCtx = () => useContext(chatContext);
